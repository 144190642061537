import * as React from 'react';
import styled from 'styled-components';
import {FormattedMessage} from '@meiko/react-intl';
import {callStatusMessage} from 'io/eniocaller/utils';
import {convertSecondsToTimer} from 'utils/time';
import Button from 'components/generic/Button';
import {CALL_STATUS} from 'io/sip/constants';

const CallInfoWrapper = styled.div`
	padding: 20px 15px;
	display: flex;
	flex-direction: column;
	gap: 10px;
	border-top: 1px solid #e0e0e0;
`;

export const CallInfo = ({callSession, timer, onEndCall, lastCustomNumber}) => {
	const isTimerVisible = React.useMemo(() => {
		if (!callSession) return false;
		return [
			CALL_STATUS.IN_CALL,
			CALL_STATUS.TERMINATING,
			CALL_STATUS.CALL_TERMINATED,
		].includes(callSession?.status);
	}, [callSession?.status]);

	const isCustomerInfoVisible = React.useMemo(() => {
		if (!callSession) return false;
		return (
			[
				CALL_STATUS.MEDIA_ADDED,
				CALL_STATUS.CALL_TERMINATED,
				CALL_STATUS.TERMINATING,
				CALL_STATUS.BUSY_HERE,
				CALL_STATUS.REQUEST_TERMINATED,
				CALL_STATUS.TEMPORARILY_UNAVAILABLE,
				CALL_STATUS.SESSION_PROGRESS,
				CALL_STATUS.IN_CALL,
				CALL_STATUS.RINGING,
			].includes(callSession?.status) && lastCustomNumber.length < 1
		);
	}, [callSession?.status, lastCustomNumber]);

	const isLastCustomNumberVisible = React.useMemo(() => {
		if (!callSession) return false;
		return (
			[
				CALL_STATUS.MEDIA_ADDED,
				CALL_STATUS.CALL_TERMINATED,
				CALL_STATUS.TERMINATING,
				CALL_STATUS.BUSY_HERE,
				CALL_STATUS.REQUEST_TERMINATED,
				CALL_STATUS.TEMPORARILY_UNAVAILABLE,
				CALL_STATUS.FORBIDDED,
				CALL_STATUS.RINGING,
			].includes(callSession?.status) && lastCustomNumber.length > 0
		);
	}, [callSession?.status, lastCustomNumber]);

	return (
		<CallInfoWrapper>
			<div>
				<div style={{fontWeight: 'bold'}}>
					<FormattedMessage id={callStatusMessage(callSession?.status)} />
				</div>
				{isTimerVisible && <div>{convertSecondsToTimer(timer)}</div>}
			</div>
			{isCustomerInfoVisible && (
				<div>
					<i className="fa fa-user" aria-hidden="true"></i>&nbsp;
					{callSession?.customerDetails?.client?.firstName}{' '}
					{callSession?.customerDetails?.client?.lastName}
					{' - '}
					{callSession?.customerDetails?.building?.address}
					{', '}
					{callSession?.customerDetails?.building?.city}
				</div>
			)}
			{isLastCustomNumberVisible && <div>{lastCustomNumber}</div>}
			{callSession?.active && (
				<div style={{display: 'flex', flexDirection: 'row', gap: 10}}>
					<Button appearance="error" onClick={onEndCall}>
						Lopeta puhelu
					</Button>
				</div>
			)}
		</CallInfoWrapper>
	);
};
