import * as React from 'react';

import Input from 'components/generic/Input';
import Button from 'components/generic/Button';
import {isValidPhoneNumber, normalizePhone} from 'utils/phone-number';

export const CallDialForm = ({onSubmit, value, onChange}) => {
	const handleSubmit = () => {
		onSubmit(value);
	};

	const handleInputChange = e => {
		const normalizedPhone = normalizePhone(e.target.value);

		if (normalizedPhone < 1) {
			onChange(normalizedPhone);
			return;
		}
		if (!/^\+?\d*$/.test(normalizedPhone)) return;
		onChange(normalizedPhone);
	};

	return (
		<div style={{padding: '15px 15px', display: 'flex', flexDirection: 'row', gap: 10}}>
			<Input
				placeholder="Puhelinnumero"
				style={{flex: 1}}
				value={value}
				onChange={handleInputChange}
				isValid={value.length > 0 ? isValidPhoneNumber(value) : true}
			/>
			<Button appearance="success" onClick={handleSubmit}>
				Soita
			</Button>
		</div>
	);
};
