// core dependencies
import React, {useMemo} from 'react';
import ReactDom from 'react-dom';
import propTypes from 'prop-types';
import styled from 'styled-components';
import Media from 'react-responsive';
import memoize from 'memoize-one';
import RouterLink from 'components/generic/RouterLink';
import Link from 'components/generic/Link';
import {FormattedMessage, injectIntl} from '@meiko/react-intl';
import {remove, isEmpty} from 'ramda';
import {
	setBgColor,
	setTextColor,
	setBgColorHover,
	setTextColorHover,
	setBorderColor,
} from 'utils/headerColors';
// custom helpers & utils
import {listUnstyled, buttonUnstyled, hrUnstyled, textStyles} from 'styles/fragments';
import {
	screenSmMin,
	screenXsMax,
	screenMdMin,
	textColorBase,
	borderColorBase,
	linkColor,
	navHeight,
	grayDark,
	fontSizeXS,
	fontWeightMedium,
	colors,
} from 'styles/constants';
import {pageDefs, hiddenPageDefs} from 'constants/pageDefs';
import {isMobileIos} from 'utils/userAgents';
import {getUserLanguage} from 'utils/loc';
import {isPilotUser, userAppPermissions} from 'utils/perms';
import {baseUrl, inProd} from 'constants/app';
import msgs from 'dicts/messages';
// components
import LinkButton from './LinkButton';
import Icon from './Icon';
import logoDarkUrl from 'assets/images/eniopro1_1.svg';
import NavbarLeftMenu from './_NavbarLeftMenu';
import NavbarNav from './_NavbarNav';
import NavbarNavItem from './_NavbarNavItem';
import {NoticeNotification, NoticesButton, NoticesList} from './_NavbarNotifications';
import Modal from 'components/generic/Modal';
import ModalHeaderTitle from 'components/generic/ModalHeaderTitle';
import Button from 'components/generic/Button';
import FeedbackForm from 'components/views/FeedbackForm';
import {encodeQuery} from 'utils/url';
import SendSmsButton from './SendSmsButton';
import SipCallerClient from 'views/SipCaller/navbar/CallerClient';
import {SIP_PROVIDER} from 'constants/caller';
// styles

const largeQuery = `(min-width: ${screenMdMin})`;
const verySmallQuery = `(max-width: ${screenXsMax})`;
const notVerySmallQuery = `(min-width: ${screenSmMin})`;

const navBorderStatic = `1px solid ${borderColorBase}`;

const NavbarWrapper = styled.div`
	z-index: 51;
	flex-shrink: 0;
	position: relative;
	display: flex;
	align-items: center;
	border-bottom: 1px solid ${colors.grey7};
	height: ${navHeight};
	${({organizationColors}) => setBgColor(organizationColors?.bg)}
	${({organizationColors}) => setTextColor(organizationColors?.text)}

	* {
		/* iOS mobile navbar blur elements need this */
		-webkit-overflow-scrolling: auto !important;
	}
`;

const LeftMenuButton = styled.button.attrs(() => ({type: 'button'}))`
	${buttonUnstyled};

	background: ${inProd ? '#fff' : '#fbcd84'};
	align-self: stretch;
	flex-shrink: 0;
	display: flex;
	align-items: center;
	border-right: 1px solid ${colors.grey7};
	padding: 0 15px;

	&:hover,
	&:active,
	&:focus {
		background: ${colors.grey7};
	}
`;

const LeftMenuWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const LeftMenuButtonTexts = styled.div`
	margin-right: 8px;
	max-width: 100%;
`;

// NOTE: could also use white-space wrap + hyphenation for long words. would be nicer, but this is easier.
const textEllipsis = `
	overflow: hidden;
	max-width: 100%;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

const NavHeader = styled.div`
	${textEllipsis};
	${textStyles.title};
	color: ${textColorBase};
`;

const PageName = styled.div`
	${textEllipsis};
	margin-top: 3px;
	font-size: ${fontSizeXS};
	color: ${colors.grey4};
`;

const LogoWrapper = styled.span`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 8px;
	color: ${textColorBase};
	height: 100%;

	${props => {
		if (!props.activeApp) {
			return 'min-width: 104px;';
		}
	}}

	> img {
		height: 40%;
	}
`;

const LeftMenu = styled.div`
	position: fixed;
	top: ${navHeight};
	overflow: auto;
	z-index: 51;
	border-left: ${navBorderStatic};
	border-right: ${navBorderStatic};
	border-bottom: ${navBorderStatic};
	max-height: calc(100vh - ${navHeight} - 10px);
	background: white;
	box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.12);
	color: ${grayDark};
	animation: fade-d 0.05s ease-out;

	min-width: 280px;
	// change min width rule at min width + desired padding
	@media (max-width: 328px) {
		min-width: calc(100% - 48px);
	}
`;

export const MenuBackdrop = styled.div`
	position: fixed;
	z-index: 50;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	opacity: 0.1;
	background: black;

	animation: fade-d 0.15s ease-out;

	@keyframes fade-d {
		from {
			opacity: 0;
		}
		to {
			opacity: 0.1;
		}
	}
`;

const RightMenuButtonWrapper = styled.button.attrs(() => ({type: 'button'}))`
	${buttonUnstyled};

	align-self: stretch;
	flex-shrink: 0;
	display: flex;
	align-items: center;
	border-left: ${({organizationColors}) => setBorderColor(organizationColors?.border)}
	padding: 0px 15px;
	height: 100%;
	align-items: center;

	&:hover,
	&:active,
	&:focus {
		${({organizationColors}) => setBgColorHover(organizationColors?.bgHover)}
		> div > div {
			${({organizationColors}) => setTextColorHover(organizationColors?.textHover)}
		}
		> span {
			${({organizationColors}) => setTextColorHover(organizationColors?.textHover)}
		}
	}
	&:hover {
		cursor: pointer;
	}
`;

const ActionsMenuButton = styled(RightMenuButtonWrapper)`
	/* NOTE: try to be about the same width as right menu button. easiest would be to set both to have a static width */
	padding: 0 18px;
`;

const RightMenuButtonTexts = styled.div`
	display: block;
	margin-left: 8px;
`;

const OrgTitle = styled.div`
	${textStyles.title};
	${({organizationColors}) => setTextColor(organizationColors?.text)}
`;

const UserIconContainer = styled.span`
	position: relative;
	display: inline-block;
`;

const StyledNoticeNotification = styled(NoticeNotification)`
	margin: 0;
	position: absolute;
	top: -4px;
	right: -4px;
`;

const RightMenuWrapper = styled.div`
	position: absolute;
	top: calc(100% + 1px);
	right: 0;
	animation: fade-d 0.05s ease-out;
	z-index: 51;
	overflow: auto;
	border-left: ${navBorderStatic};
	border-right: ${navBorderStatic};
	border-bottom: ${navBorderStatic};
	/* NOTE: this + item padding is equal to first header top padding */
	padding-bottom: 11px;
	max-height: calc(100vh - ${navHeight} - 10px);
	background: white;
	box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.12);
`;

const ActionsMenu = styled.div`
	position: absolute;
	top: calc(100% + 1px);
	/* width of the user menu button - we want to align this to the right of the screen, even though we're using absolute positioning instead of fixed */
	right: -55px;
	z-index: 51;
	/* iOS mobile fix for NavAutosuggest results not being visible */
	overflow: ${isMobileIos() ? 'visible' : 'auto'};
	padding: 15px 16px;
	border-left: ${navBorderStatic};
	border-right: ${navBorderStatic};
	border-bottom: ${navBorderStatic};
	max-height: 60vh;
	max-width: calc(100vh - 48px);
	min-width: 150px;
	background: white;
	box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.12);
	color: ${textColorBase};
`;

const ActionsMenuInner = styled.div`
	display: flex;
	flex-direction: column;
	margin: -5px -5px;

	> * {
		margin: 5px 5px;
	}
`;

const MenuHeader = styled.div`
	padding: 8px 16px;
	padding-top: 16px;
	white-space: nowrap;
	color: ${textColorBase};
	font-weight: ${fontWeightMedium};
`;

const MenuDivider = styled.hr`
	${hrUnstyled};

	border-top: ${navBorderStatic};
`;

const MenuItems = styled.ul`
	${listUnstyled};
	display: flex;
	flex-direction: column;
`;

const MenuItem = styled.li`
	> * {
		display: block;
		flex: 1;
		padding: 8px 16px;
		height: 100%;
		width: 100%;
		white-space: nowrap;
		color: ${textColorBase};

		&:hover {
			text-decoration: none;
			color: ${linkColor};
		}
	}

	${
		/*prettier-ignore*/ ({noExtraTop}) => !noExtraTop ? `
		&:first-child > * {
			padding-top: 16px;
		}
	` : ''
	};
	${
		/*prettier-ignore*/ ({noExtraBottom}) => !noExtraBottom ? `
		&:last-child > * {
			padding-bottom: 16px;
		}
	` : ''
	};
`;

const OrgItem = styled(MenuItem)`
	&& {
		border: none;
	}

	> * {
		padding-top: 8px;
		padding-bottom: 8px;
		color: ${({active}) => (active ? linkColor : textColorBase)};
	}
`;

const NavLargeWrapper = styled.div`
	flex-grow: 1;
	align-self: stretch;
	display: flex;
	align-items: center;
	overflow: hidden;
	height: 100%;
`;

const NavActions = styled.div`
	flex-grow: 1;
	display: flex;
	align-self: stretch;
	align-items: center;
	justify-content: flex-end;
	margin: -5px -5px;
	padding-right: 15px;
	white-space: nowrap;

	> * {
		margin: 5px 5px;
	}
`;

const MenuContainer = styled.div`
	position: relative;
	height: 100%;
`;

/* eslint-disable react/prop-types */
const RightMenuButton = ({
	activeOrganization,
	user,
	toggleRightMenu,
	personalNotices,
	unseenNoticesCount,
}) => {
	/* eslint-enable react/prop-types */
	return (
		<RightMenuButtonWrapper
			organizationId={activeOrganization.id}
			organizationColors={activeOrganization?.meta?.companyColors}
			onClick={toggleRightMenu}
			data-ti="navbar-right-menu-btn"
		>
			<UserIconContainer>
				<Icon name="user" width="24px" height="24px" />
				{!!personalNotices.length && (
					<StyledNoticeNotification
						organizationId={activeOrganization.id}
						length={unseenNoticesCount}
					/>
				)}
			</UserIconContainer>
			<Media query={largeQuery}>
				<RightMenuButtonTexts>
					<OrgTitle
						organizationColors={activeOrganization?.meta?.companyColors}
						organizationId={activeOrganization.id}
						data-ti="navbar-org-title"
					>
						{activeOrganization.title}
					</OrgTitle>
				</RightMenuButtonTexts>
			</Media>
		</RightMenuButtonWrapper>
	);
};

/* eslint-disable react/prop-types */
const RightMenu = ({
	activeOrganization,
	user,
	setOrganization,
	logout,
	personalNotices,
	toggleNotices,
	openFeedbackModal,
	unseenNoticesCount,
	/* eslint-enable react/prop-types */
}) => {
	const userLang = getUserLanguage(user);
	const userIsPilot = isPilotUser(user);
	// TODO: ad hoc, probably not good. docs server uses the same.
	const userHasDocsPerm = useMemo(() => userAppPermissions(user).has('users'), [user]);

	return (
		<RightMenuWrapper organizationId={activeOrganization.id} data-ti="navbar-right-menu">
			{personalNotices && (
				<NoticesButton
					toggleNotices={toggleNotices}
					unseenNoticesCount={unseenNoticesCount}
				/>
			)}
			<MenuDivider />

			{user.organizations.length > 1 && setOrganization && (
				<>
					<MenuHeader>
						<FormattedMessage id="Select organization" />
					</MenuHeader>
					<MenuItems>
						{user.organizations.map(o => (
							<OrgItem key={o.id} noExtraTop active={o.id === activeOrganization.id}>
								<LinkButton
									data-ti={'navbar-right-menu-organization-' + o.id}
									onClick={() => setOrganization(o.id)}
								>
									{o.title}
								</LinkButton>
							</OrgItem>
						))}
					</MenuItems>
					<MenuDivider />
				</>
			)}
			<MenuItems>
				{userIsPilot && (
					<MenuItem>
						<Link href={`${baseUrl}/manual/#/${userLang}/`} target="_blank">
							<FormattedMessage id="User manual" />
						</Link>
					</MenuItem>
				)}
				{userIsPilot && userHasDocsPerm && (
					<MenuItem>
						<Link href={`${baseUrl}/docs/#/${userLang}/`} target="_blank">
							<FormattedMessage id="System documentation" />
						</Link>
					</MenuItem>
				)}
				{user.accountId === 1 && (
					<MenuItem>
						<Link href="//renoa.pro/country-select">Country selector</Link>
					</MenuItem>
				)}
			</MenuItems>
			<MenuDivider />
			<MenuHeader>{user.fullName}</MenuHeader>
			<MenuItems>
				<MenuItem noExtraTop>
					<RouterLink
						to={`/profile${encodeQuery({
							referrer: window.location.pathname,
						})}`}
					>
						<FormattedMessage id="Profile" />
					</RouterLink>
				</MenuItem>
				<MenuItem>
					<LinkButton onClick={() => openFeedbackModal()}>
						<FormattedMessage id="Feedback" />
					</LinkButton>
				</MenuItem>
				<MenuItem>
					<SendSmsButton
						appearance="link"
						defaultTemplateKey="custom"
						availableTemplateKeys={['custom']}
					/>
				</MenuItem>
				<MenuItem noExtraBottom>
					<LinkButton onClick={() => logout()}>
						<FormattedMessage id="Log out" />
					</LinkButton>
				</MenuItem>
			</MenuItems>
		</RightMenuWrapper>
	);
};

const initNoticesShown = 5;

export const getPageDefs = memoize((user, appId, pathname, activeOrganization) => {
	const defs = pageDefs(user, activeOrganization);

	if (!appId) {
		return {pageDefs: defs, activeApp: null};
	}

	const iActiveApp = defs.findIndex(app => app.id === appId);

	// this can happen for instance when the user navigates to a page they don't have permissions for
	if (iActiveApp < 0) {
		// hiddenPageDefs might still have info about the active page
		const hidden = hiddenPageDefs.find(app => app.id === appId);
		if (hidden) {
			return {pageDefs: defs, activeApp: hidden};
		}
		return {pageDefs: defs, activeApp: null};
	}

	const activeApp = defs[iActiveApp];

	const iActivePage = activeApp.pages.findIndex(page =>
		pathname.match(new RegExp(`${page.to}(?:$|/)`)),
	);

	const hiddenPage = activeApp.hiddenPages.find(page =>
		pathname.match(new RegExp(`${page.to}(?:$|/)`)),
	);
	const pageNameOverride = hiddenPage ? hiddenPage.name : null;

	return {
		pageDefs: remove(iActiveApp, 1, defs),
		activeApp: {
			...activeApp,
			iActivePage,
			pageNameOverride,
			activeHiddenPage: hiddenPage,
		},
	};
});

class Navbar extends React.Component {
	state = {
		appNode: null,
		leftMenuOpen: false,
		rightMenuOpen: false,
		actionsMenuOpen: false,
		overflowMenuOpen: false,
		showNotices: false,
		feedbackModalOpen: false,
		visiblePersonalNotices: initNoticesShown,
	};

	leftMenuButtonRef = React.createRef();
	rightMenuButtonRef = React.createRef();

	constructor(props) {
		super(props);
		// since our leaddesk talk addition introduced the "view-container" div, ios safari mishandles the backdrop's z-index and doesn't put the backdrop below the dropdown menus if the backdrop is rendered at a higher level than the menus. to counteract that, we render the backdrop inside view-container. view-container should usually be present in the DOM when our constructor gets called, but sometimes it isn't, in which case we handle setting it after the initial mount (where it's guaranteed to exist).
		this.state.appNode = document.getElementById('view-container');
	}

	componentDidMount() {
		// if appNode wasn't successfully set yet, force a re-render
		if (!this.state.appNode) {
			this.setState({appNode: document.getElementById('view-container')});
		}
	}

	toggleLeftMenu = () => {
		this.closeMenus();
		this.setState({leftMenuOpen: !this.state.leftMenuOpen});
	};
	toggleRightMenu = () => {
		this.closeMenus();
		this.setState({rightMenuOpen: !this.state.rightMenuOpen});
	};
	toggleActionsMenu = () => {
		this.closeMenus();
		const currentlyOpen = this.state.actionsMenuOpen;
		this.setState({actionsMenuOpen: !currentlyOpen});
		if (this.props.onActionsDropdownOpen && !currentlyOpen) {
			this.props.onActionsDropdownOpen();
		}
	};
	toggleOverflowMenu = () => {
		this.closeMenus();
		this.setState({overflowMenuOpen: !this.state.overflowMenuOpen});
	};

	toggleNotices = () => {
		this.setState({showNotices: !this.state.showNotices});
	};

	showMoreNotices = () => {
		this.setState({
			visiblePersonalNotices: this.state.visiblePersonalNotices + initNoticesShown,
		});
	};

	closeMenus = () => {
		this.setState({
			rightMenuOpen: false,
			leftMenuOpen: false,
			actionsMenuOpen: false,
			overflowMenuOpen: false,
			showNotices: false,
		});
	};

	render() {
		const {
			intl,
			pathname,
			appId = null,
			user,
			activeOrganization,
			setOrganization = null,
			organizationChangeRedirect,
			logout,
			primaryActions,
			secondaryActionsClass: SecondaryActions,
			secondaryActionsProps,
			setNoticeSeen,
			setAllNoticesSeen,
			personalNotices,
			personalNoticesPagination,
			loadingPersonalNotices,
			getMoreNotices,
			openFeedbackModal,
			closeFeedbackModal,
			feedbackModalOpen,
			createFeedback,
			feedbackFormInitValues,
			feedbackLoading,
			personalNoticesProcessing,
			...rest
		} = this.props;

		const {leftMenuOpen, rightMenuOpen, actionsMenuOpen, overflowMenuOpen} = this.state;
		const anyMenuOpen =
			leftMenuOpen || rightMenuOpen || actionsMenuOpen || overflowMenuOpen;

		const {pageDefs, activeApp} = getPageDefs(user, appId, pathname, activeOrganization);

		// add activeApp as the first option if it exists
		const feedbackFormApps =
			activeApp && hiddenPageDefs
				? [activeApp, ...pageDefs, ...hiddenPageDefs]
				: [...pageDefs, ...hiddenPageDefs];

		// add initial subPage if user has activeApp
		// prettier-ignore
		const activeSubView = activeApp
			? !isEmpty(activeApp.pages) && activeApp.iActivePage >= 0 ? activeApp.pages[activeApp.iActivePage].to
				: activeApp.activeHiddenPage ? activeApp.activeHiddenPage.to
				: null
			: null;

		const unseenNoticesCount = personalNoticesPagination
			? personalNoticesPagination.unseenPersonalNoticesCount
			: 0;
		return (
			<NavbarWrapper
				organizationId={activeOrganization.id}
				organizationColors={activeOrganization?.meta?.companyColors}
				{...rest}
			>
				<LeftMenuButton
					organizationId={activeOrganization.id}
					onClick={this.toggleLeftMenu}
					data-ti="navbar-left-menu-btn"
				>
					{!inProd && (
						<div
							style={{
								position: 'absolute',
								color: 'red',
								top: '4px',
								left: '1px',
								transform: 'rotate(-45deg)',
							}}
						>
							Dev
						</div>
					)}
					<LogoWrapper organizationId={activeOrganization.id} activeApp={activeApp}>
						{activeApp ? (
							<Icon name={activeApp.iconName} width="26px" height="26px" />
						) : (
							<img src={logoDarkUrl} alt="Main menu" />
						)}
					</LogoWrapper>
					<LeftMenuWrapper>
						{activeApp ? (
							<React.Fragment>
								<LeftMenuButtonTexts>
									<NavHeader organizationId={activeOrganization.id}>
										{intl.formatMessage({id: activeApp.name})}
									</NavHeader>
									{activeApp.pageNameOverride ? (
										<PageName>
											{intl.formatMessage({id: activeApp.pageNameOverride})}
										</PageName>
									) : activeApp.iActivePage >= 0 ? (
										<PageName>
											{intl.formatMessage({
												id: activeApp.pages[activeApp.iActivePage].name,
											})}
										</PageName>
									) : null}
								</LeftMenuButtonTexts>
							</React.Fragment>
						) : null}
					</LeftMenuWrapper>
				</LeftMenuButton>
				{this.state.leftMenuOpen && (
					<LeftMenu>
						<NavbarLeftMenu
							pageDefs={pageDefs}
							activeApp={activeApp}
							onItemSelect={this.closeMenus}
							user={user}
						/>
					</LeftMenu>
				)}

				<Media query={largeQuery}>
					{activeApp && activeApp.pages.length > 0 && (
						<NavLargeWrapper
							organizationId={activeOrganization.id}
							onClick={this.closeMenus}
						>
							<NavbarNav
								activeOrganization={activeOrganization}
								overflowMenuOpen={overflowMenuOpen}
								toggleOverflowMenu={this.toggleOverflowMenu}
							>
								{activeApp.pages.map((page, i) => (
									<NavbarNavItem
										key={page.to}
										organizationId={activeOrganization.id}
										organizationColors={activeOrganization?.meta?.companyColors}
										to={page.to}
										active={i === activeApp.iActivePage}
										data-ti={`navbar-${page.to}`}
										data-targetid={page.to}
									>
										<FormattedMessage id={page.name} />
									</NavbarNavItem>
								))}
							</NavbarNav>
						</NavLargeWrapper>
					)}
				</Media>

				<NavActions onClick={this.closeMenus}>
					<div id="navbar-help-area" />
					{primaryActions}
					<Media query={notVerySmallQuery}>
						{SecondaryActions ? (
							<SecondaryActions {...secondaryActionsProps} isDropdown={false} />
						) : null}
					</Media>
				</NavActions>
				<Media query={verySmallQuery}>
					{SecondaryActions ? (
						<MenuContainer>
							<ActionsMenuButton
								organizationId={activeOrganization.id}
								onClick={this.toggleActionsMenu}
							>
								<Icon name="chevronDown" width="15px" height="15px" />
							</ActionsMenuButton>

							{actionsMenuOpen && (
								<ActionsMenu>
									<ActionsMenuInner>
										<SecondaryActions {...secondaryActionsProps} isDropdown={true} />
									</ActionsMenuInner>
								</ActionsMenu>
							)}
						</MenuContainer>
					) : null}
				</Media>

				{[SIP_PROVIDER.ENIO, SIP_PROVIDER.SOMIC].includes(user?.defaultCall) && (
					<SipCallerClient />
				)}

				<MenuContainer>
					<RightMenuButton
						activeOrganization={activeOrganization}
						user={user}
						toggleRightMenu={this.toggleRightMenu}
						personalNotices={personalNotices}
						unseenNoticesCount={unseenNoticesCount}
					/>
					{this.state.rightMenuOpen && (
						<RightMenu
							activeOrganization={activeOrganization}
							user={user}
							setOrganization={setOrganization}
							logout={logout}
							personalNotices={personalNotices}
							toggleNotices={this.toggleNotices}
							closeMenus={this.closeMenus}
							openFeedbackModal={openFeedbackModal}
							unseenNoticesCount={unseenNoticesCount}
						/>
					)}
					<NoticesList
						personalNotices={personalNotices}
						showNotices={this.state.showNotices}
						toggleNotices={this.toggleNotices}
						getMoreNotices={getMoreNotices}
						personalNoticesPagination={personalNoticesPagination}
						loadingPersonalNotices={loadingPersonalNotices}
						personalNoticesProcessing={personalNoticesProcessing}
						onItemSelect={nid => {
							this.closeMenus();
							setNoticeSeen(nid);
						}}
						setAllNoticesSeen={setAllNoticesSeen}
						setNoticeSeen={setNoticeSeen}
						hasUnseenNotices={unseenNoticesCount > 0}
					/>
				</MenuContainer>

				{feedbackModalOpen && (
					<Modal
						headerTitle={
							<ModalHeaderTitle>
								<FormattedMessage id="Feedback" />
							</ModalHeaderTitle>
						}
						cancelButton={
							<Button
								id="feedback-modal-cancel-button"
								onClick={() => closeFeedbackModal()}
								disabled={feedbackLoading}
							>
								<FormattedMessage id={msgs.cancel} />
							</Button>
						}
						confirmButton={
							<Button
								id="feedback-modal-confirm-button"
								type="submit"
								appearance="success"
								form="feedback-form"
								disabled={feedbackLoading}
							>
								<FormattedMessage id={msgs.save} />
							</Button>
						}
					>
						<FeedbackForm
							id="feedback-form"
							pageDefs={feedbackFormApps}
							onSubmit={() => createFeedback()}
							initialValues={{
								...feedbackFormInitValues,
								view: activeApp ? activeApp.id : '',
								subView: activeSubView ? activeSubView : '',
							}}
						/>
					</Modal>
				)}

				{this.state.appNode &&
					ReactDom.createPortal(
						<MenuBackdrop hidden={!anyMenuOpen} onClick={this.closeMenus} />,
						this.state.appNode,
					)}
			</NavbarWrapper>
		);
	}
}

// NOTE: primaryActions and secondaryActionsClass should be supplied as fragments or lists (if there are several). spacing of the items is implemented this way.
// NOTE: secondaryActionsClass should generally be defined outside of the parent render function, otherwise there can be problems with nodes constantly getting removed and re-added.

Navbar.propTypes = {
	intl: propTypes.object,
	pathname: propTypes.string.isRequired,
	primaryActions: propTypes.object,
	secondaryActionsClass: propTypes.elementType,
	secondaryActionsProps: propTypes.object,
	actions: propTypes.node,
	appName: propTypes.node,
	appId: propTypes.string,
	organizations: propTypes.arrayOf(propTypes.object),
	user: propTypes.object.isRequired,
	activeOrganization: propTypes.object.isRequired,
	setOrganization: propTypes.func,
	organizationChangeRedirect: propTypes.string,
	logout: propTypes.func.isRequired,
	onActionsDropdownOpen: propTypes.func,
	setNoticeSeen: propTypes.func,
	personalNotices: propTypes.array,
	personalNoticesPagination: propTypes.object,
	loadingPersonalNotices: propTypes.bool,
	getMoreNotices: propTypes.func,
	openFeedbackModal: propTypes.func,
	closeFeedbackModal: propTypes.func,
	feedbackModalOpen: propTypes.bool,
	createFeedback: propTypes.func,
	feedbackFormInitValues: propTypes.object,
	feedbackLoading: propTypes.bool,
};

export default styled(injectIntl(Navbar))``;
