import {action} from 'utils/redux';
import namespace from './namespace';
import {P, Nullable} from 'utils/types';

const creator = action(namespace);

const ApiToken = P.String;

export const initialize = creator('initialize');

export const reinitialize = creator('reinitialize');

export const afterLogin = creator('afterLogin', ApiToken);

export const setOrganization = creator('setOrganization', P.Number);

export const setOrganizationSilently = creator('setOrganizationSilently', P.Number);

export const setOrganizationChangeRedirect = creator(
	'setOrganizationChangeRedirect',
	Nullable(P.String),
);

export const setNoticeSeen = creator('setNoticeSeen', P.Object);

export const setAllNoticesSeen = creator('setAllNoticesSeen');

export const getMorePersonalNotices = creator('getMorePersonalNotices');

export const getMoreNotices = creator('getMoreNotices', P.Number);

export const logout = creator('logout');

export const impersonateUser = creator('impersonateUser', P.Number);

export const openFeedbackModal = creator('openFeedbackModal');

export const closeFeedbackModal = creator('closeFeedbackModal');

export const createFeedback = creator('createFeedback');

export const expandLeaddeskTalk = creator('expandLeaddeskTalk');

export const closeLeaddeskTalk = creator('closeLeaddeskTalk');

export const setCallSession = creator('setCallSession', P.Object);

export const markCallSessionTerminatedByUser = creator('markCallSessionTerminatedByUser');

export const flushCallSessionLogs = creator('flushCallSessionLogs');

export const toggleEnioCallerVisibility = creator('toggleEnioCallerVisibility');

export const toggleSipCallerExpanded = creator('toggleSipCallerExpanded');

export const setDeviceId = creator('setDeviceId');

export const updateUserMeta = creator('updateUserMeta', P.Object);

export const updateEnioCallerDetails = creator('updateEnioCallerDetails', P.Object);

export const _feedbackSaved = creator('_feedbackSaved');

export const _stopProcessingFeedback = creator('_stopProcessingFeedback');

export const _setApiToken = creator('_setApiToken', Nullable(ApiToken));

export const _setActiveOrganizationId = creator('_setActiveOrganizationId', P.Number);

export const _setPersonalNotices = creator('_setPersonalNotices');

export const _setUser = creator('_setUser', Nullable(P.Object));

export const _clearLoginData = creator('_clearLoginData');

export const _setNoticeSeen = creator('_setNoticeSeen', P.Number);

export const _setAllNoticesSeen = creator('_setAllNoticesSeen');

export const _setApiKeys = creator('_setApiKeys');

// time entry actions
export const startTimer = creator('startTimer');

export const stopTimer = creator('stopTimer');

export const clearActiveTimer = creator('clearActiveTimer');

export const _startTimer = creator('_startTimer');

export const _stopTimer = creator('_stopTimer');

export const _setTimeEntries = creator('_setTimeEntries');

export const _startProcessingTimeEntries = creator('_startProcessingTimeEntries');

export const _stopProcessingTimeEntries = creator('_stopProcessingTimeEntries');

export const _startTimerFromCalls = creator('_startTimerFromCalls');

export const _setActiveTimerId = creator('_setActiveTimerId');

export const _updateTotalTime = creator('_updateTotalTime');

export const _addPersonalNotice = creator('_addPersonalNotice', P.Object);

export const setOverviewSearchIds = creator('setOverviewSearchIds', P.Array);

export const setOverviewSearchTeams = creator('setOverViewSearchTeams', P.Array);

export const _setEnioCallerDetails = creator('_setEnioCallerDetails', Nullable(P.Object));

export const setReasonMappings = creator('setReasonMappings', P.Array);
export const makeEnioCall = creator('makeEnioCall', P.Object);

export const makeCall = creator('makeCall', P.Object);

export const setActiveCallPoolId = creator('setActiveCallPoolId', Nullable(P.Number));

export const getActiveCallPools = creator('getActiveCallPools');
export const _getActiveCallPools = creator('_getActiveCallPools', P.Array);

export const refreshDailyCallDuration = creator('refreshDailyCallDuration');

export const _refreshDailyCallDuration = creator('_refreshDailyCallDuration');

export const searchProducts = creator('searchProducts', P.Object);

export const navigateToLeadInTeamCalendar = creator(
	'navigateToLeadInTeamCalendar',
	P.Object,
);
