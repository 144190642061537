import * as React from 'react';
import {FormattedMessage} from '@meiko/react-intl';
import services from 'services';
import * as nActions from 'modules/notifications/actions';
import {shortDur} from 'constants/notifications';
import {browserSettingsLink} from 'utils/browserSettingsLink';
import Button from 'components/generic/Button';

const copySettingToClipBoard = async value => {
	services.get('store').dispatch(
		nActions.success({
			id: 'clipboard-copied',
			duration: shortDur,
			message: services.get('intl').formatMessage({id: 'Link copied to clipboard'}),
		}),
	);
	navigator.clipboard.writeText(value);
};

export default function AudioTest() {
	const settingsLink = browserSettingsLink();

	return (
		<div
			style={{
				fontSize: '12px',
				padding: '10px 15px',
				borderTop: '1px solid #f0f0f0',
				display: 'flex',
				flexDirection: 'column',
				gap: 10,
			}}
		>
			<div>1. Kopioi linkki 2. Avaa uusi vällilehti 3. Liitä linkki osoitekenttään</div>
			<div style={{display: 'flex', flexDirection: 'row', gap: 10}}>
				<input value={settingsLink} readOnly="readonly" style={{flex: 1}}></input>
				<Button appearance="success" onClick={() => copySettingToClipBoard(settingsLink)}>
					<i className="fa fa-clipboard" aria-hidden="true"></i>&nbsp;
					<FormattedMessage id="Copy link" />
				</Button>
			</div>
		</div>
	);
}
