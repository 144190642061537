import * as React from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {withRouter} from 'react-router-dom';
import {compose} from 'ramda';
import {FormattedMessage} from '@meiko/react-intl';
import {callStatusMessage} from 'io/eniocaller/utils';
import {callSession, deviceId, sipCallerExpanded, user} from 'modules/common/selectors';
import {
	toggleSipCallerExpanded,
	makeEnioCall,
	markCallSessionTerminatedByUser,
} from 'modules/common/actions';
import {applyDispatch, applyState} from 'utils/redux';
import {convertSecondsToTimer} from 'utils/time';
import {isPilotUser} from 'utils/perms';
import {isValidPhoneNumber} from 'utils/phone-number';
import {colors} from 'styles/constants';
import {CALL_STATUS} from 'io/sip/constants';
import getSipModule from 'io/sip';
import {CallInfo} from './CallInfo';
import {CallDialForm} from './CallDialForm';
import AudioTest from './AudioTest';
import VolumeControl from './VolumeControl';

const Wrapper = styled.div`
	position: relative;
	padding: 0 15px;
	width: 280px;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
	flex-shrink: 0;
	border-left: 1px solid #ddd;
	height: 100%;
	font-size: 12px;
`;

const CallButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 30px;
	height: 30px;
	border-radius: 100%;
	cursor: pointer;
	border: none;

	${({callStatus}) => {
		switch (callStatus) {
			case CALL_STATUS.IN_CALL:
				return `background-color: red;`;
			case CALL_STATUS.TRYING:
			case CALL_STATUS.RINGING:
			case CALL_STATUS.OK:
				return `
					animation: calling 4s infinite;

					@keyframes calling {
						0%,
						50%,
						100% {
							background-color: ${colors.grey3}
						}

						25%,
						75% {
							background-color: ${colors.success2}
						}
					}
				`;
			default:
				return `background-color: white;`;
		}
	}}
`;

const MenuButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	width: 26px;
	height: 26px;
	border-radius: 100%;
	cursor: pointer;
	border: none;
	background-color: transparent;
	color: inherit;
	&:hover {
		background-color: rgba(0, 0, 0, 0.05);
	}
`;

const ExpandedWrapper = styled.div`
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	height: auto;
	background-color: white;
	z-index: 1000;
	box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.1);
	border-radius: 0 0 10px 10px;
	border: 1px solid #ddd;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	color: #000;
`;

const ActiveSessionWrapper = styled.div`
	display: flex;
	flex-direction: column;
	font-size: 12px;
	gap: 16;
	flex: 1;
	overflow: hidden;
`;

const AudioTestWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 10px;
	font-size: 11px;
	padding: 10px 15px;
	border-top: 1px solid #f0f0f0;
	color: #444;
`;

const EnioCallerClient = props => {
	const {
		callSession,
		toggleSipCallerExpanded,
		sipCallerExpanded,
		user,
		makeEnioCall,
		markCallSessionTerminatedByUser,
	} = props;

	const [callTimer, setTimer] = React.useState(
		callSession?.answerTime ? Math.floor(Date.now() / 1000) - callSession?.answerTime : 0,
	);

	const [isTestAudioVisible, setIsTestAudioVisible] = React.useState(false);
	const [customNumber, setCustomNumber] = React.useState('');
	const [lastCustomNumber, setLastCustomNumber] = React.useState('');

	const isPilot = user ? isPilotUser(user) : false;

	React.useEffect(() => {
		let timeoutId;
		if (callSession?.status === CALL_STATUS.RINGING && callTimer !== 0) {
			setTimer(0);
		}
		if (callSession?.status === CALL_STATUS.CALL_TERMINATED) {
			const callStopped = callSession?.endTime - callSession?.answerTime;
			if (callTimer !== callStopped && callTimer !== 0) {
				setTimer(callSession?.endTime - callSession?.answerTime);
			}
			clearTimeout(timeoutId);
			return;
		}
		if (callSession?.status === CALL_STATUS.IN_CALL) {
			timeoutId = setTimeout(() => {
				const currentTimestamp = Math.floor(Date.now() / 1000);
				setTimer(currentTimestamp - callSession?.answerTime);
			}, 1000);
		}
	}, [callSession, callTimer]);

	React.useEffect(() => {
		if (callSession.status === CALL_STATUS.TRYING && customNumber.length > 0) {
			setCustomNumber('');
		} else if (
			callSession.status === CALL_STATUS.TRYING &&
			customNumber.length < 1 &&
			lastCustomNumber.length > 0
		) {
			setCustomNumber('');
			setLastCustomNumber('');
		}
	}, [callSession]);

	const handleCallButtonClick = React.useCallback(() => {
		if (!callSession) {
			return;
		}
		if (callSession.active) {
			handleEndCall();
			return;
		}
		if (!isValidPhoneNumber(customNumber)) {
			return;
		}
		setLastCustomNumber(customNumber);
		makeEnioCall({user: user, number: customNumber});
	}, [callSession, customNumber, user]);

	const handleEndCall = React.useCallback(() => {
		const sipModule = getSipModule({
			provider: user.defaultCall,
			isPilot,
		});
		sipModule.markCallNonActive();
		if (sipModule.canHangup()) {
			sipModule.hangup();
			markCallSessionTerminatedByUser();
		}
	}, [user]);

	return (
		<Wrapper>
			<CallButton onClick={handleCallButtonClick} callStatus={callSession?.status}>
				<i className="fa fa-phone" style={{fontSize: '16px'}} />
			</CallButton>
			{!callSession?.active ? (
				<div style={{fontWeight: 'bold', flex: 1}}>
					<FormattedMessage id={callStatusMessage(callSession?.status)} />
				</div>
			) : (
				<ActiveSessionWrapper>
					<div style={{fontSize: '11px', fontWeight: 'bold'}}>
						<FormattedMessage id={callStatusMessage(callSession?.status)} />
						{callSession?.status === CALL_STATUS.IN_CALL && (
							<span> {convertSecondsToTimer(callTimer)}</span>
						)}
					</div>
					<div
						style={{
							fontWeight: 'bold',
							width: '100%',
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
						}}
					>
						{callSession?.customerDetails?.client?.firstName}{' '}
						{callSession?.customerDetails?.client?.lastName}
					</div>
					<div>{callSession?.customerDetails?.client?.phone || lastCustomNumber}</div>
				</ActiveSessionWrapper>
			)}
			<MenuButton
				onClick={() => toggleSipCallerExpanded()}
				style={{
					color: 'inherit',
				}}
			>
				<i className="fa fa-chevron-down" style={{fontSize: '10px'}} />
			</MenuButton>
			{sipCallerExpanded && (
				<ExpandedWrapper>
					{callSession?.active === false && (
						<CallDialForm
							onSubmit={handleCallButtonClick}
							value={customNumber}
							onChange={setCustomNumber}
						/>
					)}

					<CallInfo
						callSession={callSession}
						timer={callTimer}
						onEndCall={handleEndCall}
						lastCustomNumber={lastCustomNumber}
					/>

					<VolumeControl />

					<AudioTestWrapper>
						<a
							href="/#"
							style={{cursor: 'pointer', color: 'inherit'}}
							onClick={e => {
								e.preventDefault();
								setIsTestAudioVisible(isTestAudioVisible => !isTestAudioVisible);
							}}
						>
							<FormattedMessage id="Settings" />
						</a>
						{isPilot && <div>Beta</div>}
					</AudioTestWrapper>
					{isTestAudioVisible && <AudioTest />}
				</ExpandedWrapper>
			)}
		</Wrapper>
	);
};

export default compose(
	withRouter,
	connect(
		applyState({callSession, deviceId, sipCallerExpanded, user}),
		applyDispatch({
			toggleSipCallerExpanded,
			makeEnioCall,
			markCallSessionTerminatedByUser,
		}),
	),
)(EnioCallerClient);
