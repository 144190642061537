import * as React from 'react';
import styled from 'styled-components';

const busyhere = document.getElementById('busyhereBeta');
const callEndedAudio = document.getElementById('callended');
const callAudio = document.getElementById('audio-remote');

const Wrapper = styled.div`
	width: 100%;
	padding: 10px 15px;
	background-color: #f9f9f9;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 20px;
	border-top: 1px solid #ddd;
`;

export default function VolumeControl() {
	const [volume, setVolume] = React.useState(100);

	return (
		<Wrapper>
			<i className="fa fa-volume-up" style={{fontSize: '16px'}} />
			<input
				type="range"
				defaultValue={volume}
				min={0}
				max={100}
				step={5}
				onChange={e => {
					const newVolume = e.target.value / 100;
					setVolume(e.target.value);
					busyhere.volume = newVolume;
					callEndedAudio.volume = newVolume;
					callAudio.volume = newVolume;
				}}
				style={{width: '100%'}}
			/>
			<div style={{flexShrink: 0}}>&nbsp;{volume} %</div>
		</Wrapper>
	);
}
