//@ts-check

import * as EnioStable from 'io/eniocaller/stable/calls';
import * as EnioBeta from 'io/eniocaller/beta/calls';
import * as Somic from 'io/somic/stable/calls';
import {SIP_PROVIDER} from 'constants/caller';

// Typedefs
/**
 * @typedef {{client: object, building: object, user: object, appName: string}} SipCallClientParams
 * @returns {Promise<void>}
 */

/**
 * @typedef {{client: object, building: object, user: object, appName: string}} getModuleParams
 *
 */

/**
 * @typedef {function(): void} MarkCallNonActiveParams
 */

/**
 * @typedef {function(): boolean} CanHangupParams
 */

/**
 * @typedef {function(): void} HangupParams
 */

/**
 * @typedef SipCallerModule
 * @property {MarkCallNonActiveParams} markCallNonActive Marks the call non active.
 * @property {function} sipCallClient The sipCallClient.
 * @property {CanHangupParams} canHangup - Whether the call can be hung up.
 * @property {HangupParams} hangup - Hangup the call.
 */

/**
 * "Dynamically" import the correct client based on the environment.
 *
 * We cannot actually use dynamic imports as the callClient is sometimes resolved in effect-handler which do not support async functions.
 *
 * @param {{provider: string, isPilot: boolean}} The provider and isPilot flag.
 * @returns {SipCallerModule} The correct (beta/stable) sip caller module.
 */
const getModule = ({provider, isPilot = false}) => {
	if (provider === SIP_PROVIDER.ENIO) {
		return isPilot ? EnioBeta : EnioStable;
	} else if (provider === SIP_PROVIDER.SOMIC) {
		return Somic;
	}

	throw new Error('Unknown provider for SIP module!');
};

export default getModule;
